.App {
  text-align: center;
}
html{
  font-size: 24px;
  color:#994d00;
  color:#71706e;
  
}

*{
  font-family:'Courier New', Courier, monospace;

}
h1{text-shadow: 1px 1px 2px black;}
.hero{
  height:30vh;
}

.flexContainer{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.noteContainer{
  padding:15px;
  /*border: 4px solid  antiquewhite;*/
  color:#ffb366;
border-radius: 5px;
 
} 

.test{
  display: none;
}
.test:hover{
  display: block;
}

.margin-left{
  margin-left:10px;
}
.margin-left2{
  margin-left:40px;
}
.long{
 width:  500px;
}
.margin-top{
  margin-top:20px;
}

.button{
  margin-left: 10px;
  border: 0.5px solid beige;
  box-shadow:0px 2px 4px 2px rgba(0,0,0,0.2);
padding : 10px;
border-radius: 5px;
background-image: linear-gradient( to bottom right, var(--primary),var(--secondary));}
.break{
  height:100px;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
 /* .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }*/
}



.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
/* Card Css*/
:root{
  --primary:yellow;
  --primary:#f8efb9;

  --secondary:purple;
  --secondary: #ffdff4;
  --dark: black;
  --light:white;
}

.card{
  margin: 30px 15px;
  width:160px;
  height: 200px;
  font-size: 22px;
 
}



.card_inner{
  width:100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position:relative;
}
.card_inner.is-flipped{
  transform:rotateY(180deg);
}

.card_face{
  position:absolute;
  width:100%;
  height:100%;
  backface-visibility: hidden;
  overflow: hidden;
  border-radius: 16px;
  box-shadow:0px 3px 18px 3px rgba(0,0,0,0.2);
}
.card_face--front{
  background-image: linear-gradient( to bottom right, var(--primary),var(--secondary));
  display: flex;
  align-items: center;
  justify-content: center;
}
.line{
  height:5px;
  margin:0 auto;
  margin-bottom: 20px;
  width: 70%;
  
}


.card_face--back{
  background-color: var(--light);
  transform: rotateY(180deg);
}

.card_content{
  width:  100%;
  height: 100%;

}
.card_header{
  position:relative;
  padding: 5px 10px 20px;
}
.card_header::after{
  content: '';
  display:block;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  z-index: -1;
  border-radius: 0 0 50% 0;
  background-image: linear-gradient(to bottom left, var(--primary) 10%, var(--secondary)115%);
 
}